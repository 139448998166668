import { Injectable } from '@angular/core';
import { AlertController,  ModalController, ToastController } from '@ionic/angular';
import { LoadingService } from '../loading-service/loading.service';
import { SystemLogService } from '../systemlog-service/systemlog.service';
import { VivaInfoPopupComponent } from 'src/app/controls/viva-info-popup/viva-info-popup.component';
import { TranslatorService } from '../translator-service/translator.service';
import { TimeoutError } from 'rxjs';
import { VivaErrorPopupComponent } from 'src/app/controls/viva-error-popup/viva-error-popup.component';
import { VivaWarningPopupComponent } from 'src/app/controls/viva-warning-popup copy/viva-warning-popup.component';
import { TokenService } from '../token/token.service';


@Injectable({
  providedIn: 'root',
})
export class PromptService {

  constructor(protected _alertController: AlertController,
    private _toast: ToastController,
    private _loading: LoadingService,
    private _systemLog: SystemLogService,
    protected modalController: ModalController,
    private _TranslatorService: TranslatorService
  ) { }



  public async throwError(
    errorObject: any,
    statusText: string,
    title: string = 'Error'
  ) {

    if (errorObject) {
      this._systemLog.logError(errorObject, statusText, title);
    }
    const alert = await this._alertController.create({
      header: title,
      message: statusText,
      buttons: ['OK'],
      cssClass: 'custom-error-alert',//
    });

    await this._loading.closeAllLoaders();
    await alert.present();
    await alert.onDidDismiss();

  }

 

  public async throwNewtorkError(err: any) {
    this._systemLog.logError(err, 'Network error', 'Network error');
    await this._loading.closeAllLoaders();


    if (err && err.error
      && err.error.message) {

      if (err.error.message.indexof('Unknown') > 0) {
        await this.showToastMessage(this._TranslatorService.translateText('prompt-service.server-error'));
      } else {

        const alert = await this._alertController.create({
          header: 'Server error',
          message: err.error.message,
          buttons: ['OK'],
          cssClass: 'custom-error-alert',//
        });

        await alert.present();
        await alert.onDidDismiss();
      }
    } else {

      await this.showToastMessage(this._TranslatorService.translateText('prompt-service.server-not-reachable'));
    }
  }

  public async showError(err: string, title: string = 'Error') {
    const modal = await this.modalController.create({
      component: VivaErrorPopupComponent,
      componentProps: {
        message: err
      },
      cssClass: 'custom-info-modal'
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  public async showMessage(
    statusText: string
  ) {
    const modal = await this.modalController.create({
      component: VivaInfoPopupComponent,
      componentProps: {
        message: statusText
      },
      cssClass: 'custom-info-modal'
    });

    await modal.present();
    await modal.onDidDismiss();
  }

  public async showToastMessage(message: string, duration: number = 2000) {
    const toast = await this._toast.create({
      message: message,
      duration: duration,
      position: 'bottom'
    });

    await toast.present();
  }

  private isShowingWarningMessage: boolean = false;
  public async showWarningMessage(
    message: string
  ) {

    if (this.isShowingWarningMessage)
      return;

    this.isShowingWarningMessage = true;
    try {

      const modal = await this.modalController.create({
        component: VivaWarningPopupComponent,
        componentProps: {
          message: message
        },
        cssClass: 'custom-info-modal'
      });
  
      await modal.present();
      await modal.onDidDismiss();
    } finally {
      this.isShowingWarningMessage = false;
    }

  }

  public async confirmationMessage(message: string, yesText?:string,noText?:string): Promise<boolean> {

    if(!yesText){
      yesText = this._TranslatorService.translateText('prompt-service.yes');
    }

    if(!noText){
      noText = this._TranslatorService.translateText('prompt-service.no');
    }

    return new Promise<boolean>(async (resolve) => {
      let alert = await this._alertController.create({
        cssClass: 'custom-confirmation-alert',  
        message: message,
        buttons: [
          {
            text: 'No',
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: 'Yes',
            handler: () => {
              resolve(true);
            }
          }
        ]
      });

      await alert.present();
      // No need to await onDidDismiss here since resolve is handled inside the button handlers
    });
  }


  private lastTimeout: Date | null = null;
  private resetTimout:any | null = null;

  public async showNetworkConnectionError(error: unknown,source:string, showGenericError: boolean = true) {
    console.error('Network error - ' + source, error);
    if (error instanceof TimeoutError) {  // Ensure we check the instance type properly
      const now = new Date();
      const oneMinuteAgo = new Date(now.getTime() - 60 * 1000);  // 60,000 milliseconds = 1 minute

      if (this.lastTimeout === null || this.lastTimeout < oneMinuteAgo) {
       // await this.showToastMessage(this._TranslatorService.translateText('prompt-service.server-not-reachable')+'\n'+source, 2000);
        this.lastTimeout = now;
      }
    } else if (showGenericError) {
      await this.throwNewtorkError(error);
    }

    if (this.resetTimout) {
      clearTimeout(this.resetTimout);
      this.resetTimout = null;
    }
    this.resetTimout = setTimeout(() => {
      this.lastTimeout = null;
      if (this.resetTimout)
        clearTimeout(this.resetTimout);
      this.resetTimout = null;
    }, 60000 * 5);
  }

}