import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { StorageKey } from './models/storage-key.enum';

import {
  SecureStorage,
  StorageError,
} from '@aparajita/capacitor-secure-storage'
import { SecureStorageKey } from './models/secure-storage-key.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  private initialized = false;

  public async init(): Promise<void> {
    // Simulate some initialization logic (e.g., checking token, refreshing, etc.)
    this.initialized = true;
  }

  public isInitialized(): boolean {
    return this.initialized;
  }

  private syncronized = false;

  async set(key: StorageKey, value: string): Promise<boolean> {
    await SecureStorage.setSynchronize(this.syncronized);
    return await SecureStorage.set(key, value, false, this.syncronized).then(() => true).catch(() => false);
  }

  async get(key: StorageKey): Promise<string | null> {
    await SecureStorage.setSynchronize(this.syncronized);
    return await SecureStorage.get(key, false, this.syncronized)
      .then((response) => {
        return response as string;
      }
      ).catch(() => null);
  }

  async remove(key: StorageKey): Promise<boolean> {
    await SecureStorage.setSynchronize(this.syncronized);    
    return await SecureStorage.remove(key, this.syncronized)
      .then(() => true).catch(() => false);
  }

  async clear(): Promise<boolean> {
    await SecureStorage.setSynchronize(this.syncronized);
    return await SecureStorage.clear(this.syncronized)
      .then(() => true).catch(() => false);
  }

  async keys(): Promise<Array<SecureStorageKey>> {
    await SecureStorage.setSynchronize(this.syncronized);
    return await SecureStorage.keys(this.syncronized)
      .then((response) => response as SecureStorageKey[])
      .catch(() => []);
  }
}
